<template>
  <div>
    <!-- <Loading /> -->
    <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <card>
        <div slot="header">
          <h4 class="card-title">
            Registrazione
          </h4>
        </div>
        <div>
          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ passed, failed }"
          >
            <fg-input  type="email"
                       :error="failed ? 'Il campo Email è obbligatorio': null"
                       :hasSuccess="passed"
                       name="email"
                       v-model="form.email"
                       placeholder="Email">
            </fg-input>
          </ValidationProvider>

          <ValidationProvider
            vid="confirmation"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input  type="password"
                       :error="failed ? 'Il campo Password è obbligatorio': null"
                       :hasSuccess="passed"
                       name="password"
                       v-model="form.password"
                       placeholder="Password">
            </fg-input>
          </ValidationProvider>

          <ValidationProvider
            rules="required|confirmed:confirmation"
            v-slot="{ passed, failed }"
          >
            <fg-input  type="password"
                       :error="failed ? 'Il campo Conferma Password è obbligatorio': null"
                       :hasSuccess="passed"
                       name="confirm"
                       v-model="form.confirmPassword"
                       placeholder="Conferma Password">
            </fg-input>
          </ValidationProvider>

          <ValidationProvider
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input  type="text"
                       :error="failed ? 'Il campo Nome è obbligatorio': null"
                       :hasSuccess="passed"
                       name="first_name"
                       v-model="form.first_name"
                       placeholder="Nome">
            </fg-input>
          </ValidationProvider>

          <ValidationProvider
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input  type="text"
                       :error="failed ? 'Il campo Cognome è obbligatorio': null"
                       :hasSuccess="passed"
                       name="last_name"
                       v-model="form.last_name"
                       placeholder="Cognome">
            </fg-input>
          </ValidationProvider>

          <ValidationProvider
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input  type="text"
                       :error="failed ? 'Il campo Ragione Sociale è obbligatorio': null"
                       :hasSuccess="passed"
                       name="ragione_sociale"
                       v-model="form.ragione_sociale"
                       placeholder="Ragione Sociale">
            </fg-input>
          </ValidationProvider>

          <ValidationProvider
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input  type="text"
                       :error="failed ? 'Il campo Partita Iva è obbligatorio': null"
                       :hasSuccess="passed"
                       name="partita_iva"
                       v-model="form.partita_iva"
                       placeholder="Partita Iva">
            </fg-input>
          </ValidationProvider>

          <!-- <fg-input>
            <checkbox v-model="subscribe"
                      name="subscribe">Subscribe to newsletter
            </checkbox>
          </fg-input> -->
        </div>
        <Loading v-if="!hasLoaded"/>
        <div v-else class="card-footer text-right">
          <button :disabled="disabled" type="submit" class="btn btn-fill btn-info btn-wd">Registrati</button>
        </div>

      </card>
    </form>
  </ValidationObserver>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";
import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
  // import {Checkbox} from 'src/components/index'
import AuthenticationService from 'src/services/AuthenticationService'


extend("email", email);
extend("required", required);
extend("confirmed", confirmed);

export default {
  components: {
    // Checkbox
    Loading
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        confirmPassword: "",
        first_name: "",
        last_name: "",
        ragione_sociale: "",
        partita_iva: ""
      },
      hasLoaded: true,
      disabled: false
      // subscribe: false
    };
  },
  methods: {
    submit() {
      // alert("Form has been submitted!");
      this.register()
    },
    async register() {
      try {
        this.hasLoaded = false
        this.disabled = true
        const customer = await AuthenticationService.register({customer: this.form})
        if (customer) {
          this.$store.dispatch('customer/setUser', customer.data)
          this.$router.push({
            name: 'Login'
          })
        }
      } catch(err) {
        console.log('err', err)
      } finally {
        this.hasLoaded = true
        this.disabled = false
      }
    }
  }
}
</script>
<style>
</style>
