import axios from 'axios'
import store from 'src/store'
import config from 'src/config'

export default () => {
  return axios.create({
    baseURL: config.backend_url,
    headers: {
      Authorization: `Bearer ${store.state.customer.token}`,
      'Access-Control-Allow-Origin': '*'
    }
  })
}
