<template>
  <div class="row">
    <div class="col col-centered">
      <edit-profile-form :customer="customer">
      </edit-profile-form>
    </div>    
  </div>
</template>
<script>
  import EditProfileForm from './UserProfile/EditProfileForm.vue'
  import CustomerService from 'src/services/CustomerService'

  export default {
    components: {
      EditProfileForm
    },
    data(){
      return{
        //customer:this.$store.state.customer
      }      
    },
    computed: {
        customer() {
            return this.$store.state.customer;
        },
    },
    async mounted() {
      console.log( this.customer );
//    try {
//      const customer = await CustomerService.getCustomer(this.$store.state.customer.user.id)
//      if(customer){
//        this.customer = customer
//        //this.$store.state.customer = customer
//      }
//      
//    } catch(err) {
//      console.log(err)
//    } finally {        
//    }
    }
  }

</script>
<style>
.col-centered{
    max-width: 80%;
    margin:auto;
}

</style>
