<template>
  <div >
    <label v-if="logo === null">Logo</label><br/>
            <img v-bind:src="imageUrl" v-if="imageUrl !==''" width="150px" height="150px" style="padding:10px">
      <b-form-file
        v-model="fileLogoTmp"
        v-if="logoTmp === null"
        placeholder="Scegli un file o spostalo qui"
        drop-placeholder="Rilascia il file qui..."
      ></b-form-file>  
      <fg-input type="text"
                v-if="logoTmp !==null"
                label="Logo"
                :disabled="false"
                placeholder="Logo file"
                v-model="logoTmp">
      </fg-input>            
  </div>
</template>

<script>
import store from '@/store'
import CustomerService from 'src/services/CustomerService'
import axios from 'axios'

export default {
  props:['logo','fileLogo'],
  name:'Logo',
  data(){
    return{
      logoTmp:this.logo,
      fileLogoTmp:this.fileLogo,
      fileLogoChanged:false,
      imageUrl: ''
    }
  },
  watch:{
    fileLogoTmp (value) {
        if(value){
          this.fileLogoChanged = true 
          const file = this.fileLogoTmp
          this.imageUrl = URL.createObjectURL(file);
          this.$emit('change',file)      
        }else{
          this.fileLogoChanged = false;
          this.downloadWithAxios()
        }      
      }          
  },
  mounted(){
    this.downloadWithAxios()
  },
  methods:{    
    async downloadWithAxios() {
        CustomerService.downloadLogo(store.state.customer.token)
          .then((response) => {
            const imageUrl = window.URL.createObjectURL(new Blob([response.data]))
            this.imageUrl = imageUrl;                   
          })
      }        
  }
}
</script>