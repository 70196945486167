<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="!hasLoaded"
      fixed
      no-wrap
      variant="light"
      opacity="0.85"
      blur="5px"
    >
    </b-overlay>
    <div class="row custom_error" style="margin-bottom:25px;" v-if="error">
      <p style="color:red;">{{ error }} </p>
    </div>
    <div class="row" v-if="hasLoaded">
      <div class="col-xl-3 col-md-6">
        <stats-card :title="NumCompanies" subTitle="Aziende">
          <div slot="header" class="icon-warning">
            <i class="nc-icon nc-single-02 text-warning"></i>
          </div>
          <template slot="footer">
            <i class="fa fa-line-chart"></i>Aziende registrate
          </template>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card :title="NumSolleciti" subTitle="Solleciti">
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-notes text-success"></i>
          </div>
          <template slot="footer">
            <i class="fa fa-envelope"></i>Solleciti totali
          </template>
        </stats-card>
      </div>
      
      <div class="col-xl-3 col-md-6">
        <stats-card :title="NumItems" subTitle="File">
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-single-copy-04 text-danger"></i>
          </div>
          <template slot="footer">
            <i class="fa fa-file-text"></i>File ricevuti
          </template>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card :title="NumReminders" subTitle="Programma">
          <div slot="header" class="icon-info">
            <i class="nc-icon nc-chart-bar-32 text-primary"></i>
          </div>
          <template slot="footer">
            <i class="fa fa-calendar-check-o"></i>Solleciti programmati
          </template>
        </stats-card>
      </div>
      <div class="col-xl-3 col-md-6">
        <card-title-html :title="TempoRisparmiato" subTitle="Tempo">
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-time-alarm text-success"></i>
          </div>
          <template slot="footer">
            <i class="fa fa-envelope"></i>Tempo risparmiato
          </template>
        </card-title-html>
      </div>

    </div>
    <!-- <div class="row" style="border-bottom: 1px solid #D3D3D3;">
      <h4>Solleciti Inviati</h4>
    </div> -->
    <div class="row" style="margin-top:20px;">
      <div class="col-lg-12">
        <card title="Solleciti Inviati" subTitle="Numero di solleciti inviati divisi per mese">
          <div id="chartViews" class="ct-chart"></div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {ChartCard, StatsCard, Card, Table as LTable, Checkbox,CardTitleHtml} from 'src/components/index'
import CustomerService from 'src/services/CustomerService'
const moment = require('moment');
export default {
  components: {
    Checkbox,
    Card,
    LTable,
    ChartCard,
    StatsCard,
    CardTitleHtml
  },
  data() {
    return {
      error: null,
      hasLoaded: false,
      customer: null,
      $Chartist: null
    }
  },
  async mounted() {
    try {
      const customer = await CustomerService.getCustomer(this.$store.state.customer.user.id)
      console.log('customer', customer)
      this.customer = customer.data
      let chartist = await import('chartist')
      this.$Chartist = chartist.default ? chartist.default : chartist;
      this.initViewsChart()
    } catch(err) {
      console.log('err', err)
      this.error = 'Errore durante il caricamento della Dashboard'
    } finally {
      this.hasLoaded = true
    }
  },
  computed: {
    NumCompanies: function() {
      return this.customer.companies.length.toString()
    },
    NumSolleciti: function() {
      return this.customer.templates.length.toString()
    },
    TempoRisparmiato:function(){
      //todo trasformarlo in giorni ore e minuti
      //console.log("templates",this.customer.templates)
      const time = this.customer.templates.length * 5
      var t = ''
      var day=Math.floor(time/1440);
    var rem=Math.floor(time%1440);
    var hour=Math.floor(rem/60);
    var Minute=Math.floor(rem%60);

    if(day>0)
        t += day+" gg <br/>";

    if(hour>0)
        t += hour+" ore <br/>";

    if(Minute>0)
        t += Minute+" min";

      
       return  t;
    },
    NumItems: function() {
      let tot = 0
      this.customer.reminders.map(reminder => {
        let data = reminder.data
        Object.keys(data).forEach(item => {
          if (data[item])
            tot++
        })
      })
      return tot.toString()
    },
    NumReminders: function() {
      return this.customer.reminders.length.toString()
    },
    getRemindersSent: function() {
      var counter = this.getChartLabels.counter;
      var totInMonths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.customer.reminders.map(reminder => {
        var slots = reminder.slots
        for(let i = 0; i<slots.length; i++) {
          if ( slots[ i ].sent === false ) { continue; }
          var date = slots[i].date
          var dm = moment( date, 'YYYY-MM-DD' );
          if (new Date() >= new Date(date))
            totInMonths[new Date(date).getMonth()] += 1
            counter[`${ dm.month() + 1 }/${ dm.year() }`] += 1;
        }
      })
      console.log( counter );
      return Object.keys( counter ).map( x => counter[ x ] );
    },
    getChartLabels() {
      var month = moment().month();
      var year = moment().year();
      var labels = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
      var counter = {};
      var res = [];
      var last = null;
      for ( var i = 0; i < labels.length; i++ ) {
        if ( i === month ) {
          last = i * 1;
        } else if ( last !== null && i > last ) {
          res.push( labels[ i ] + `\n${ year - 1 }` );
          counter[ `${ i + 1 }/${ year - 1 }` ] = 0;
        }
      }
      console.log( res );
      for ( var a = 0; a < last; a++ ) {
        res.push( labels[ a ] + `\n${ year }` );
        counter[ `${ a + 1 }/${ year }` ] = 0;
      }
      res.push( labels[ last ] + `\n${ year }` );
      counter[ `${ last + 1 }/${ year }` ] = 0;
      console.log( counter );
      return { counter, labels: res };
    },
  },
  methods: {
    initViewsChart () {
      const dataViews = {
        labels: this.getChartLabels.labels,
        series: [
          this.getRemindersSent
        ]
      }
      const optionsViews = {
        seriesBarDistance: 10,
        classNames: {
          bar: 'ct-bar'
        },
        axisX: {
          showGrid: false
        },
        height: '250px'
      }
      const responsiveOptionsViews = [
        ['screen and (max-width: 640px)', {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc (value) {
              return value[0]
            }
          }
        }]
      ]
      this.$Chartist.Bar('#chartViews', dataViews, optionsViews, responsiveOptionsViews)
    }
  }
}
</script>

<style>

</style>
