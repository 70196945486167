var backend =  {
  local: 'http://127.0.0.1:3001/',
  development: '',
  staging: 'https://piperapi.digitalautomations.it/',
  production: 'https://api.pipercloud.it/'
}
var frontend = {
  local: 'http://127.0.0.1:8080/',
  development: '',
  staging: 'https://piperapp.digitalautomations.it/login',
  production: 'https://app.pipercloud.it/'
}
module.exports = {
  backend_url: backend.staging,
  frontend_url: frontend.staging,
  // pagination: {
  //   rows_per_page: 60
  // }
}
