import Api from '@/services/Api'
import axios from 'axios'
import config from '@/config'

export default {
  register (data) {
    return Api().post('/model/customer/register?include=true', data)
  },
  login (credentials) {
    return Api().post('/model/customer/authenticate?include=true', credentials)
  },
  sendEmailResetPassword (email) {
    return Api().post('/model/customer/changepsw', email)
  },
  confirmTokenAuthResetPassword (token) {
    return axios.create({
      baseURL: config.backend_url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).get('/model/customer/jwt-validation')
  },
  resetPassword(token, password) {
    return axios.create({
      baseURL: config.backend_url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).post('/model/customer/save-password', password)
  }
}