<template>

  <card>
    <div slot="header">
      <h4 class="title">Edit Profile</h4>
    </div>
    <div>
      <form>
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="First Name"
                      placeholder="First Name"
                      v-model="user.first_name">
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text"
                      label="Last Name"
                      placeholder="Last Name"
                      v-model="user.last_name">
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="email"
                      placeholder="Email"
                      v-model="user.email">
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text"
                      label="PEC"
                      placeholder="PEC"
                      v-model="pec">
            </fg-input>
          </div>
          
        </div>
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="Phone"
                      placeholder="Phone"
                      v-model="telefono">
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text"
                      label="Cell"
                      placeholder="Cell"
                      v-model="cell">
            </fg-input>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="Company"
                      :disabled="true"
                      placeholder="Paper dashboard"
                      v-model="user.ragione_sociale">
            </fg-input>
          </div>
          <div class="col-md-6">            
            <fg-input type="text"
                      label="VAT"
                      :disabled="true"
                      placeholder="Partita IVA"
                      v-model="user.partita_iva">
            </fg-input>
          </div>          
        </div>

        <div class="row">
          <div class="col-md-8">
            <fg-input type="text"
                      label="Mittente & logo"
                      :disabled="false"
                      placeholder="Mittente"
                      v-model="sender">
            </fg-input>
          </div>
          
        </div>
        <div class="row">
          
          <div class="col-md-8">
                        
            <logo :logo="logo" :fileLogo="fileLogo" @change="setFile"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <fg-input type="text"
                      label="Signature"
                      :disabled="true"
                      placeholder="Firma"
                      >
                      <textarea  id="textarea_signature" class="form-control" placeholder="Signature (non obbligatorio)" 
                      v-model="signature"
                      v-on:keydown.enter="$event.stopPropagation()"
                      rows="9"></textarea>
            </fg-input>
          </div>          
        </div>

        <hr/>
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="Address"
                      placeholder="Home Address"
                      v-model="indirizzo">
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text"
                      label="City"
                      placeholder="City"
                      v-model="citta">
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="Country"
                      placeholder="Country"
                      v-model="paese">
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text"
                      label="CAP"
                      placeholder="CAP"
                      v-model="cap">
            </fg-input>
          </div>
        </div>

        <div class="text-center">
          <button type="submit" class="btn btn-success btn-fill btn-wd" @click.prevent="updateProfile">
            Update Profile
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>
  
</template>
<script>
import Vue from 'vue'
import { FormFilePlugin } from 'bootstrap-vue'
Vue.use(FormFilePlugin)
import CustomerService from 'src/services/CustomerService'
import Logo from './Logo'
import store from '@/store'
import Swal from 'sweetalert2'

  export default {
    props:['customer'],
    components: {
      Logo
    },
    data(){
      return{
        pec:'',
        cell:'',
        email:'',
        telefono:'',
        cap:'',
        citta:'',
        paese:'',
        indirizzo:'',
        sender:'',
        signature:'',
        fileLogo:null,
        logo:null ,        
        user:{},
        imageUrl:''

      }      
    },
    mounted(){
      
      this.user = this.customer.user
      this.pec = this.user.metas && this.user.metas.contatti ? this.user.metas.contatti.pec : ''
      this.cell = this.user.metas && this.user.metas.contatti ? this.customer.user.metas.contatti.cell : ''
      this.telefono = this.user.metas && this.user.metas.contatti ? this.user.metas.contatti.telefono : ''
      
      this.cap = this.user.metas && this.user.metas.indirizzo ? this.user.metas.indirizzo.cap : ''
      this.citta = this.user.metas && this.user.metas.indirizzo ? this.user.metas.indirizzo.citta : ''
      this.paese = this.user.metas && this.user.metas.indirizzo ? this.user.metas.indirizzo.paese : ''
      this.indirizzo = this.user.metas && this.user.metas.indirizzo ? this.user.metas.indirizzo.indirizzo : ''
      this.sender = this.user.metas && this.user.metas.company ? this.user.metas.company.sender : ''
      this.signature = this.user.metas && this.user.metas.company ? this.user.metas.company.signature : ''
      
      this.logo = this.user.metas && this.user.metas.logo && this.user.metas.logo ? this.user.metas.logo : null      
           
    },
    methods: { 
      setFile(data){
        this.fileLogo = data;
      },
      async uploadLogo() {
          var ris = null
          console.log("call upload logo")
          try {
            var formData = new FormData();
            formData.append('files', this.fileLogo)
            ris = await CustomerService.uploadLogo(formData, store.state.customer.token);
            this.$store.dispatch('customer/setUserOnly', ris.data);
          } catch(err) {
            console.log('err', err)        
          }
      },  
      async updateProfile () {

        this.user.metas.contatti = this.user.metas.contatti || {}
        this.user.metas.indirizzo = this.user.metas.indirizzo || {}
        this.user.metas.company = this.user.metas.company || {}
        this.user.metas.contatti.pec = this.pec
        this.user.metas.contatti.cell = this.cell
        this.user.metas.contatti.telefono = this.telefono
        this.user.metas.indirizzo.cap = this.cap
        this.user.metas.indirizzo.citta = this.citta
        this.user.metas.indirizzo.paese = this.paese
        this.user.metas.indirizzo.indirizzo = this.indirizzo
        this.user.metas.sender = this.sender
        
        this.user.metas.company.signature = this.signature
          
        try {
          const customer = await CustomerService.update(this.user.id,this.user);
          console.log( customer );
          if ( customer.data ) {
              this.$store.dispatch('customer/setUserOnly', customer.data);
              if ( customer && this.fileLogo ) {
                  this.uploadLogo();
              }
              Swal.fire({
                  title: "Profilo aggiornato correttamente",
                  type: "success",
                  confirmButtonClass: "btn btn-success",
                  buttonsStyling: false
              });
          }
        } catch(err) {
          Swal.fire({
              title: "Si è verificato un errore",
              type: "danger",
              confirmButtonClass: "btn btn-danger",
              buttonsStyling: false
          });
        }finally{
        }
      }
    }
  }

</script>
<style>

</style>
