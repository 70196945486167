export const customer = {
  namespaced: true,
  state: {
    token: null,
    user: null,
    isUserLoggedIn: false
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      if (token) {
        state.isUserLoggedIn = true
      } else {
        state.isUserLoggedIn = false
      }
    },
    setUser (state, user) {
      state.user = user
    },
    setUserOnly (state, user) {
      state.user.user = user
    },
    setTokenResetPassword (state, token) {
      state.token = token
    }
  },
  actions: {
    setToken ({ commit }, token) {
      commit('setToken', token)
    },
    setUser ({ commit }, user) {
      commit('setUser', user)
    },
    setUserOnly ({ commit }, user) {
      commit('setUser', user)
    },
    setTokenResetPassword ({ commit }, token) {
      commit('setTokenResetPassword', token)
    }
  }
}
