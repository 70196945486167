var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"wrapper"}},[_c('b-overlay',{attrs:{"id":"overlay-background","show":!_vm.hasLoaded,"fixed":"","no-wrap":"","variant":"light","opacity":"0.85","blur":"5px"}}),(_vm.hasLoaded && _vm.tokenValid)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"}),_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('h2',[_vm._v("Reimposta passowrd")]),_c('card',{staticClass:"stacked-form",staticStyle:{"margin-top":"35px"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("\n                Cambia Password\n              ")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","label":"Nuova Password","error":failed ? 'Il campo Password è obbligatorio': null,"hasSuccess":passed,"name":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm_password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","label":"Conferma Password","error":failed ? 'Il campo Conferma Password è obbligatorio': null,"hasSuccess":passed,"name":"confirm_password"},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}})]}}],null,true)}),_c('button',{staticClass:"btn btn-fill btn-info",attrs:{"type":"submit"}},[_vm._v("Invia")])],1)],2)]}}],null,false,3381183817)})],1)],1),_c('div',{staticClass:"col-md-3"})]):_vm._e(),_c('div',{staticClass:"col-xs-12 text-center"},[(_vm.hasLoaded && !_vm.tokenValid)?_c('h2',{staticStyle:{"margin-top":"150px","color":"#f91942"}},[_vm._v("Link non valido!")]):_vm._e(),(_vm.hasLoaded && _vm.succes)?_c('h2',{staticStyle:{"margin-top":"150px","color":"green"}},[_vm._v("Password Aggiornata!")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }