import Api from '@/services/Api'
import { updateLocale } from 'moment'
import axios from 'axios'
import config from 'src/config'

export default {
  getCustomer (customer_id) {
    return Api().get(`/model/customer/${customer_id}?include=true`)
  },
  createGroup (data) {
    return Api().post(`/model/group?include=true`, data)
  },
  getGroups () {
    return Api().get(`/model/group?include=true`)
  },
  update (customer_id,data){
    return Api().patch(`/model/customer/${customer_id}?include=true`,data)
  },
  uploadLogo (data, token) {
    return axios.create({
      baseURL: config.backend_url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }).patch('/model/customer/upload/logo', data)
  },
  downloadLogo(token){
    return axios.create({
      baseURL: config.backend_url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      },
      responseType:'blob'
    }).get('/model/customer/download/logo')
  },
}
