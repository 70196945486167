var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-layout',{attrs:{"pageClass":"login-page"}},[_c('div',{staticClass:"row d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-8"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('fade-render-transition',[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h3',{staticClass:"card-title text-center"},[_vm._v("Login")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","error":failed ? 'Il campo Email è obbligatorio': null,"hasSuccess":passed,"label":"Email","name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'Il campo Password è obbligatorio': null,"hasSuccess":passed,"name":"password","label":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),(!_vm.hasLoaded)?_c('Loading'):_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-fill btn-info btn-round btn-wd ",attrs:{"type":"submit"}},[_vm._v("Login")]),_c('br'),_c('div',{staticClass:"forgot"},[_c('router-link',{staticClass:"card-category",attrs:{"to":"/login"}},[_c('p',{on:{"click":function($event){return _vm.reset_password()}}},[_vm._v("Password dimenticata?")])])],1),(_vm.error)?_c('div',[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.error)+" ")])]):_vm._e(),(_vm.reset_psw)?_c('div',[_c('p',{staticStyle:{"color":"green"}},[_vm._v("Successo! Controlla la casella di posta ")])]):_vm._e(),_c('div',{staticStyle:{"text-align":"center","font-size":"8pt","color":"grey"}},[_c('em',[_vm._v("vers 1.3.4")])])])],1)],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }