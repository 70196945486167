import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
import ResetPassword from 'src/pages/GeneralViews/ResetPassword.vue'
// Dashboard pages
import Overview from 'src/pages/Dashboard/Dashboard/Overview.vue'
import CustomOverview from 'src/pages/Dashboard/Dashboard/CustomOverview.vue'
import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue'

// Pages
import User from 'src/pages/Dashboard/Pages/UserProfile.vue'
import TimeLine from 'src/pages/Dashboard/Pages/TimeLinePage.vue'
import Login from 'src/pages/Dashboard/Pages/Login.vue'
import Register from 'src/pages/Dashboard/Pages/Register.vue'
import Lock from 'src/pages/Dashboard/Pages/Lock.vue'

// Components pages
import Buttons from 'src/pages/Dashboard/Components/Buttons.vue'
import GridSystem from 'src/pages/Dashboard/Components/GridSystem.vue'
import Panels from 'src/pages/Dashboard/Components/Panels.vue'
const SweetAlert = () => import('src/pages/Dashboard/Components/SweetAlert.vue')
import Notifications from 'src/pages/Dashboard/Components/Notifications.vue'
import Icons from 'src/pages/Dashboard/Components/Icons.vue'
import Typography from 'src/pages/Dashboard/Components/Typography.vue'

// Forms pages
const RegularForms = () => import('src/pages/Dashboard/Forms/RegularForms.vue')
const ExtendedForms = () => import('src/pages/Dashboard/Forms/ExtendedForms.vue')
const ValidationForms = () => import('src/pages/Dashboard/Forms/ValidationForms.vue')
const Wizard = () => import('src/pages/Dashboard/Forms/Wizard.vue')

// TableList pages
const RegularTables = () => import('src/pages/Dashboard/Tables/RegularTables.vue')
const ExtendedTables = () => import('src/pages/Dashboard/Tables/ExtendedTables.vue')
const PaginatedTables = () => import('src/pages/Dashboard/Tables/PaginatedTables.vue')
const GestioneSolleciti = () => import('src/pages/Dashboard/Pages/Solleciti/GestioneSolleciti.vue')
const CreazioneSolleciti = () => import('src/pages/Dashboard/Pages/Solleciti/CreazioneSolleciti.vue')
const EditSollecito = () => import('src/pages/Dashboard/Pages/Solleciti/EditSollecito.vue')
const EditCliente = () => import('src/pages/Dashboard/Forms/EditCliente.vue')
// Maps pages
const GoogleMaps = () => import('src/pages/Dashboard/Maps/GoogleMaps.vue')
const FullScreenMap = () => import('src/pages/Dashboard/Maps/FullScreenMap.vue')
const VectorMaps = () => import('src/pages/Dashboard/Maps/VectorMapsPage.vue')
// Reminders
const GestioneReminders = () => import('src/pages/Dashboard/Pages/Reminder/GestioneReminders.vue')
const Reminder = () => import('src/pages/Dashboard/Pages/Reminder/Reminder.vue')
const CreateReminder = () => import('src/pages/Dashboard/Pages/Reminder/CreateReminder.vue')
// Calendar
const Calendar = () => import('src/pages/Dashboard/Calendar/CalendarRoute.vue')
// Charts
const Charts = () => import('src/pages/Dashboard/Charts.vue')
// Upload Items
const UploadItems = () => import('src/pages/Dashboard/Pages/Upload/UploadItems')

// Store
import store from 'src/store'

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }

  ]
}
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Creazione Cliente',
      component: Wizard
    }
  ]
}

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Gestione Clienti',
      component: PaginatedTables,
    }]
}

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      component: FullScreenMap
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login,
  beforeEnter: (to, from, next) => {
    if (store.state.customer.isUserLoggedIn) {
      next({path: '/admin/overview'})
    } else {
      next()
    }
  }
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register,
  beforeEnter: (to, from, next) => {
    if (store.state.customer.isUserLoggedIn) {
      next({path: '/admin/overview'})
    } else {
      next()
    }
  }
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

let UploadItemsPage = {
  path: '/upload-items/:token',
  name: 'UploadItems',
  component: UploadItems
}

const routes = [
  {
    path: '/',
    redirect: '/admin/overview'
  },
  // componentsMenu,
  // formsMenu,
  // tablesMenu,
  // mapsMenu,
  pagesMenu,
  loginPage,
  // registerPage,
  // lockPage,
  UploadItemsPage,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Dashboard',
        component: CustomOverview,
        beforeEnter: (to, from, next) => {
          if (!store.state.customer.isUserLoggedIn) {
            next({path: '/login'})
          } else {
            next()
          }
        }
      },
      {
        path: 'creazione-cliente',
        name: 'Creazione Cliente',
        component: Wizard,
        beforeEnter: (to, from, next) => {
          if (!store.state.customer.isUserLoggedIn) {
            next({path: '/login'})
          } else {
            next()
          }
        }
      },
      {
        path: 'gestione-clienti',
        name: 'Gestione Clienti',
        component: PaginatedTables,
        beforeEnter: (to, from, next) => {
          if (!store.state.customer.isUserLoggedIn) {
            next({path: '/login'})
          } else {
            next()
          }
        }
      },
      {
        path: 'edit-cliente/:id',
        name: 'Modifica Cliente',
        component: EditCliente,
        beforeEnter: (to, from, next) => {
          if (!store.state.customer.isUserLoggedIn) {
            next({path: '/login'})
          } else {
            next()
          }
        }
      },
      {
        path: 'gestione-solleciti',
        name: 'Gestione Solleciti',
        component: GestioneSolleciti,
        beforeEnter: (to, from, next) => {
          if (!store.state.customer.isUserLoggedIn) {
            next({path: '/login'})
          } else {
            next()
          }
        }
      },
      {
        path: 'creazione-solleciti',
        name: 'Creazione Solleciti',
        component: CreazioneSolleciti,
        beforeEnter: (to, from, next) => {
          if (!store.state.customer.isUserLoggedIn) {
            next({path: '/login'})
          } else {
            next()
          }
        }
      },
      {
        path: 'edit-sollecito/:id',
        name: 'Modifica Solleciti',
        component: EditSollecito,
        beforeEnter: (to, from, next) => {
          if (!store.state.customer.isUserLoggedIn) {
            next({path: '/login'})
          } else {
            next()
          }
        }
      },
      {
        path: 'gestione-reminders',
        name: 'Gestione Invii',
        component: GestioneReminders,
        beforeEnter: (to, from, next) => {
          if (!store.state.customer.isUserLoggedIn) {
            next({path: '/login'})
          } else {
            next()
          }
        }
      },
      {
        path: 'gestione-reminders/:id',
        name: 'Gestione Invii',
        component: Reminder,
        beforeEnter: (to, from, next) => {
          if (!store.state.customer.isUserLoggedIn) {
            next({path: '/login'})
          } else {
            next()
          }
        }
      },
      {
        path: 'creazione-reminder',
        name: 'Pianificazione Invio',
        component: CreateReminder,
        beforeEnter: (to, from, next) => {
          if (!store.state.customer.isUserLoggedIn) {
            next({path: '/login'})
          } else {
            next()
          }
        }
      },
      // {
      //   path: 'stats',
      //   name: 'Stats',
      //   component: Stats
      // },
      // {
      //   path: 'calendar',
      //   name: 'Calendar',
      //   component: Calendar
      // },
      // {
      //   path: 'charts',
      //   name: 'Charts',
      //   component: Charts
      // }
    ]
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    name: 'reset-password'
  },
  {path: '*', component: NotFound}
]

export default routes
