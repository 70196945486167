<template>
  <div id="wrapper">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
    </b-overlay>
    <div class="row" v-if="hasLoaded && tokenValid">
      <div class="col-md-3"></div>
      <div class="col-xs-12 col-md-6">
        <h2>Reimposta passowrd</h2>
        <card class="stacked-form" style="margin-top:35px;">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
              <template slot="header">
                <h4 class="card-title">
                  Cambia Password
                </h4>
              </template>
              <div>
                <ValidationProvider
                    name="password"
                    rules="required|min:8"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="password"
                               label="Nuova Password"
                               :error="failed ? 'Il campo Password è obbligatorio': null"
                               :hasSuccess="passed"
                               name="password"
                               v-model="password">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="confirm_password"
                    rules="required|min:8"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="password"
                               label="Conferma Password"
                               :error="failed ? 'Il campo Conferma Password è obbligatorio': null"
                               :hasSuccess="passed"
                               name="confirm_password"
                               v-model="confirm_password">
                    </fg-input>
                  </ValidationProvider>
                <button type="submit" class="btn btn-fill btn-info">Invia</button>
              </div>
          </form>
          </ValidationObserver>
        </card>
      </div>
      <div class="col-md-3"></div>
    </div>
    <div class="col-xs-12 text-center">
      <h2 style="margin-top: 150px; color: #f91942;" v-if="hasLoaded && !tokenValid">Link non valido!</h2>
      <h2 style="margin-top: 150px; color: green;" v-if="hasLoaded && succes">Password Aggiornata!</h2>
    </div>
  </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
import { required, min } from "vee-validate/dist/rules"
import { extend } from "vee-validate";

extend("required", required);
extend("min", min)

export default {
  name: 'reset-password',
  data() {
    return {
      hasLoaded: false,
      tokenValid: false,
      token: null,
      password: '',
      confirm_password: '',
      error: null,
      succes: false
    }
  },
  components: {
  },
  async mounted() {
    if (!this.$route.query.token) {
      console.log('no token')
      this.tokenValid = false
      setTimeout(() => {
        this.$router.push({
          path: '/'
        })
      }, 3000)
    } else {
      this.token = this.$route.query.token
      console.log('si token')
      // this.$store.dispatch('user/setToken', this.$route.query.token)
      try {
        let ris = await AuthenticationService.confirmTokenAuthResetPassword(this.$route.query.token)
        console.log('ris', ris)
        if (ris) {
          this.tokenValid = true
        }
      } catch(error) {
        console.log('Token non valido', error)
        this.tokenValid = false
        // setTimeout(() => {
        //   this.$router.push({
        //     path: '/'
        //   })
        // }, 3000)
      }
    }
    this.hasLoaded = true
  },
  methods: {
    submit() {
      this.ChangePassword()
    },
    async ChangePassword() {
      try {
        this.hasLoaded = false
        this.succes = false
        this.error = null
        if(this.password !== this.confirm_password)
          this.error = "Le due passowrd devono coincidere"
        else {
          const ris = await AuthenticationService.resetPassword(this.token, {new_password: this.password})
          console.log('ris', ris)
          this.succes = true
          setTimeout(() => {
            this.$router.push({
              path: '/login'
            })
          }, 3000)
        }
      } catch(err) {
        console.log('err', err)
        this.error = 'Errore durante il cambio password!'
        setTimeout(() => {
          this.error = null
        }, 3000)
      } finally {
        this.hasLoaded = true
      }
    }
  }
}
</script>

<style>

</style>