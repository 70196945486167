<template>
  <div class="user">
    <!-- <div class="photo">
      <img src="static/img/default-avatar.png" alt="avatar"/>
    </div> -->
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click.stop="toggleMenu" href="#">
           <span>
             {{title}}
             <b class="caret"></b>
          </span>
      </a>
      <div class="clearfix"></div>
      <div class="mt-3">
        <el-collapse-transition>
          <ul class="nav" v-show="!isClosed">
            <slot>
              <li style="cursor:pointer;">
                <a class="profile-dropdown" href="#">
                  <span class="sidebar-mini"><i class="nc-icon nc-button-power"></i></span>
                  <span class="sidebar-normal" @click="logout()">Logout</span>
                </a>
              </li>
              <li>
                <a class="profile-dropdown" href="#pablo">
                  <span class="sidebar-mini">MP</span>
                  <router-link :to="{path:'/pages'}" class="sidebar-normal" style="padding:0">Mio Profilo</router-link>
                </a>
              </li>
              
              <!-- <li>
                <a class="profile-dropdown" href="#pablo">
                  <span class="sidebar-mini">S</span>
                  <span class="sidebar-normal">Settings</span>
                </a>
              </li> -->
              <li>
                <a class="profile-dropdown" href="https://digitalautomations.it/piper-supporto/" target="_blank">
                  <span class="sidebar-mini">SP</span>
                  <span class="sidebar-normal">Support</span>
                </a>
              </li>
            </slot>
            
          </ul>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

  export default {
    components: {
      [CollapseTransition.name]: CollapseTransition
    },
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        isClosed: true
      }
    },
    methods: {
      toggleMenu () {
        this.isClosed = !this.isClosed
      },
      logout() {
        this.$store.dispatch('customer/setToken', null)
        this.$store.dispatch('customer/setUser', null)
        this.$router.push({
          path: '/login'
        })
      }
    }
  }
</script>
<style>
  .collapsed {
    transition: opacity 1s;
  }
</style>
