<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Login</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="email"
                               :error="failed ? 'Il campo Email è obbligatorio': null"
                               :hasSuccess="passed"
                               label="Email"
                               name="email"
                               v-model="email">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="password"
                               :error="failed ? 'Il campo Password è obbligatorio': null"
                               :hasSuccess="passed"
                               name="password"
                               label="Password"
                               v-model="password">
                    </fg-input>
                  </ValidationProvider>
                  <!-- <fg-input>
                    <l-checkbox v-model="subscribe">
                      Subscribe to newsletter
                    </l-checkbox>
                  </fg-input> -->
                </div>
                <Loading v-if="!hasLoaded"/>
                <div v-else class="text-center">
                  <button type="submit" class="btn btn-fill btn-info btn-round btn-wd ">Login</button>
                  <br>
                  <div class="forgot">
                    <router-link to="/login" class="card-category">
                     <p @click="reset_password()">Password dimenticata?</p>
                    </router-link>
                  </div>
                  <div v-if="error">
                    <p style="color:red;">{{ error }} </p>
                  </div>
                  <div v-if="reset_psw">
                    <p style="color:green;">Successo! Controlla la casella di posta </p>
                  </div>
                  <div style="text-align: center; font-size: 8pt; color: grey"><em>vers 1.3.4</em></div>
                </div>
                
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import AuthenticationService from 'src/services/AuthenticationService'
import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'

extend("email", email);
extend("required", required);
extend("min", min);

  export default {
    components: {
      LCheckbox,
      FadeRenderTransition,
      AuthLayout,
      Loading
    },
    data() {
      return {
        email: "",
        password: "",
        subscribe: true,
        hasLoaded: true,
        error: null,
        reset_psw: false
      };
    },
    methods: {
      submit() {
        this.login()
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      async reset_password () {
        // this.email_state = null
        if (this.hasLoaded) {
          this.hasLoaded = false
          this.error = null
          // this.error_email = null
          if (this.email === '') {
            this.error = "Inserisci l'email!"
            // this.error_email = "Inserisci l'email!"
            // this.email_state = false
            this.hasLoaded = true
            setTimeout(() => {
              this.error = null
              // this.error_email = null
            }, 3000)
          } else {
            try {
              let ris = await AuthenticationService.sendEmailResetPassword({email: this.email})
              if (ris) {
                this.error = null
                // this.error_email = null
                // this.email_state = null
                // this.reset = true
                this.hasLoaded = true
              }
            } catch(error) {
              // this.error_email = "Errore! Contatta l'assistenza tecnica"
              error.response ? this.error = error.response.data.message : this.error = error + "! Contatta l'assistenza"
            } finally {
              this.hasLoaded = true
              this.reset_psw = true
              setTimeout(() => {
                this.error = null
                this.reset_psw = false
              }, 3000)
            }
          }
        }
      },
      async login() {
        try {
          this.error = null
          this.hasLoaded = false
          const customer = await AuthenticationService.login({
            email: this.email,
            password: this.password
          })
          if (customer) {
            this.$store.dispatch('customer/setToken', customer.data.token) // serve?
            this.$store.dispatch('customer/setUser', customer.data)
            this.$router.push({
              path: '/'
            })
          }
        } catch(err) {
          console.log('err', err)
          this.error = 'Credenziali Errate'
        } finally {
          this.hasLoaded = true
          setTimeout(() => {
            this.error = null
          }, 3000)
        }
    }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
