var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("\n          Registrazione\n        ")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","error":failed ? 'Il campo Email è obbligatorio': null,"hasSuccess":passed,"name":"email","placeholder":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'Il campo Password è obbligatorio': null,"hasSuccess":passed,"name":"password","placeholder":"Password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'Il campo Conferma Password è obbligatorio': null,"hasSuccess":passed,"name":"confirm","placeholder":"Conferma Password"},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Il campo Nome è obbligatorio': null,"hasSuccess":passed,"name":"first_name","placeholder":"Nome"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Il campo Cognome è obbligatorio': null,"hasSuccess":passed,"name":"last_name","placeholder":"Cognome"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Il campo Ragione Sociale è obbligatorio': null,"hasSuccess":passed,"name":"ragione_sociale","placeholder":"Ragione Sociale"},model:{value:(_vm.form.ragione_sociale),callback:function ($$v) {_vm.$set(_vm.form, "ragione_sociale", $$v)},expression:"form.ragione_sociale"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Il campo Partita Iva è obbligatorio': null,"hasSuccess":passed,"name":"partita_iva","placeholder":"Partita Iva"},model:{value:(_vm.form.partita_iva),callback:function ($$v) {_vm.$set(_vm.form, "partita_iva", $$v)},expression:"form.partita_iva"}})]}}],null,true)})],1),(!_vm.hasLoaded)?_c('Loading'):_c('div',{staticClass:"card-footer text-right"},[_c('button',{staticClass:"btn btn-fill btn-info btn-wd",attrs:{"disabled":_vm.disabled,"type":"submit"}},[_vm._v("Registrati")])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }